//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Body bg
$body-bg-dark: $coal-100;

// Card
$card-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
$card-box-shadow-dark: none;
$card-border-enabled: true;
$card-border-color: $gray-200;
$card-border-color-dark: $dark-light-dark;
