// All Poppins Variations

@font-face {
  font-family: 'Poppins-Thin';
  font-display: swap;
  font-weight: 100;
  font-style: normal;
  src: url('./Poppins/Poppins-Thin.ttf');
}
@font-face {
  font-family: 'Poppins-ThinItalic';
  font-display: swap;
  font-weight: 100;
  font-style: italic;
  src: url('./Poppins/Poppins-ThinItalic.ttf');
}
@font-face {
  font-family: 'Poppins-ExtraLight';
  font-display: swap;
  font-weight: 200;
  font-style: normal;
  src: url('./Poppins/Poppins-ExtraLight.ttf');
}
@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  font-display: swap;
  font-weight: 200;
  font-style: italic;
  src: url('./Poppins/Poppins-ExtraLightItalic.ttf');
}
@font-face {
  font-family: 'Poppins-Light';
  font-display: swap;
  font-weight: 300;
  font-style: normal;
  src: url('./Poppins/Poppins-Light.ttf');
}
@font-face {
  font-family: 'Poppins-LightItalic';
  font-display: swap;
  font-weight: 300;
  font-style: italic;
  src: url('./Poppins/Poppins-LightItalic.ttf');
}
@font-face {
  font-family: 'Poppins-Regular';
  font-display: swap;
  font-weight: 400;
  font-style: normal;
  src: url('./Poppins/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins-Italic';
  font-display: swap;
  font-weight: 400;
  font-style: italic;
  src: url('./Poppins/Poppins-Italic.ttf');
}
@font-face {
  font-family: 'Poppins-Medium';
  font-display: swap;
  font-weight: 500;
  font-style: normal;
  src: url('./Poppins/Poppins-Medium.ttf');
}
@font-face {
  font-family: 'Poppins-Medium-Italic';
  font-display: swap;
  font-weight: 500;
  font-style: italic;
  src: url('./Poppins/Poppins-MediumItalic.ttf');
}
@font-face {
  font-family: 'Poppins-SemiBold';
  font-display: swap;
  font-weight: 600;
  font-style: normal;
  src: url('./Poppins/Poppins-SemiBold.ttf');
}
@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  font-display: swap;
  font-weight: 600;
  font-style: italic;
  src: url('./Poppins/Poppins-SemiBoldItalic.ttf');
}
@font-face {
  font-family: 'Poppins-Bold';
  font-display: swap;
  font-weight: 700;
  font-style: normal;
  src: url('./Poppins/Poppins-Bold.ttf');
}
@font-face {
  font-family: 'Poppins-BoldItalic';
  font-display: swap;
  font-weight: 700;
  font-style: italic;
  src: url('./Poppins/Poppins-BoldItalic.ttf');
}
@font-face {
  font-family: 'Poppins-ExtraBold';
  font-display: swap;
  font-weight: 800;
  font-style: normal;
  src: url('./Poppins/Poppins-ExtraBold.ttf');
}
@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  font-display: swap;
  font-weight: 800;
  font-style: italic;
  src: url('./Poppins/Poppins-ExtraBoldItalic.ttf');
}
@font-face {
  font-family: 'Poppins-Black';
  font-display: swap;
  font-weight: 900;
  font-style: normal;
  src: url('./Poppins/Poppins-Black.ttf');
}
@font-face {
  font-family: 'Poppins-BlackItalic';
  font-display: swap;
  font-weight: 900;
  font-style: italic;
  src: url('./Poppins/Poppins-BlackItalic.ttf');
}
