:root {
  --amplify-colors-primary-100: #{$primary} !important;
  --amplify-colors-primary-90: #{$primary} !important;
  --amplify-colors-primary-80: #{$primary} !important;
  --amplify-colors-primary-60: #{$primary} !important;
  --amplify-colors-primary-40: #{$primary} !important;
  --amplify-colors-primary-20: #{$primary} !important;
  --amplify-colors-primary-10: #{$primary} !important;

  .amplify-input {
    padding: #{$input-btn-padding-y} #{$input-btn-padding-x} #{$input-btn-padding-y} #{$input-btn-padding-x};
    border-radius: $input-border-radius;
    border-color: $input-border-color;
  }

  .amplify-button {
    padding: #{$input-btn-padding-y} #{$input-btn-padding-x} #{$input-btn-padding-y} #{$input-btn-padding-x};
    border-radius: $input-border-radius;
  }
}

[data-amplify-authenticator] {
  --amplify-components-authenticator-form-padding: 0px;
  --amplify-components-button-link-active-background-color: transparent;
  --amplify-components-button-link-focus-background-color: transparent;
  --amplify-components-button-link-hover-background-color: transparent;
  --amplify-components-authenticator-router-border-style: none;
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-authenticator-router-background-color: $body-bg;

  label {
    font-weight: bolder;
  }
}
