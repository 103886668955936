@import './fonts/fonts.scss';
@import './amplify';

$font-family-anoLab: 'Poppins-Regular';

th[sortable] {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  box-sizing: content-box;
}

th[sortable].desc span:after,
th[sortable].asc span:after {
  content: '';
  display: inline-flex;
  vertical-align: middle;
  background: url('../../../../media/icons/duotune/arrows/arr073.svg') no-repeat;
  background-size: 18px;
  width: 18px;
  height: 18px;
  margin-left: 0.25em;
  box-sizing: content-box;
}

th[sortable].desc span:after {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
