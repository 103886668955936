//
// Toolbar
//

// Form controls
:is(
    [data-kt-app-layout='light-sidebar'],
    [data-kt-app-layout='light-header'],
    [data-kt-app-layout='dark-header']
  ) {
  .app-toolbar {
    .form-select {
      background-color: var(--#{$prefix}body-bg) !important;
    }
  }
}

// Desktop mode
@include media-breakpoint-up(lg) {
  .app-toolbar {
    [data-kt-app-layout='light-sidebar'] & {
      border-top: 1px solid var(--#{$prefix}border-color);
    }

    body:not([data-kt-app-toolbar-fixed='true']) & {
      height: auto;
      background-color: transparent;
      border-top: 0;
      border-bottom: 0;
      box-shadow: none;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .app-toolbar {
    body:not([data-kt-app-toolbar-fixed-mobile='true']) & {
      height: auto;
      background-color: transparent;
      border-top: 0;
      border-bottom: 0;
      box-shadow: none;
    }
  }
}
