@use '@angular/material' as mat;

@include mat.core();

$theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: mat.$violet-palette,
    ),
    typography: (
      brand-family: 'Poppins-Regular',
      bold-weight: 900,
    ),
    density: (
      scale: -1,
    ),
  )
);

:root {
  @include mat.all-component-themes($theme);
}

/* You can add global styles to this file, and also import other style files */
@import './assets/sass/style.scss';
@import './assets/sass/plugins.scss';
@import './assets/sass/style.angular.scss';

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import './assets/plugins/keenicons/duotone/style.css';
@import './assets/plugins/keenicons/outline/style.css';
@import './assets/plugins/keenicons/solid/style.css';

// to set Position of Support-Button Widget to left!
iframe#launcher-frame {
  left: 35px !important;
}
div#freshworks-frame-wrapper {
  left: 30px !important;
}
